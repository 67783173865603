import React, {useState, useEffect} from 'react';
import './App.css';

const TypingEffect = ({text,speed, delayBeforeRestart})=>{
  const[displayedText, setDisplayedText] = useState('');
  const[index, setIndex] = useState(0);

  useEffect(()=>{
    if(index<text.length){
      const timeoutId = setTimeout(()=>{
        setDisplayedText((prev)=>prev+text[index]);
        setIndex(index+1);
      },speed);
    return()=>clearTimeout(timeoutId);
    } else{
      const restartTimeout = setTimeout(()=>{
        setDisplayedText('');
        setIndex(0);
      }, delayBeforeRestart);
      return () =>clearTimeout(restartTimeout);
    }
  },[index, text, speed, delayBeforeRestart]);
  return <p>{displayedText}</p>;
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <TypingEffect text="Welcome to Hetvik Hudda's world!" speed={100} delayBeforeRestart={2000}/>
      </header>
    </div>
  );
}

export default App;
